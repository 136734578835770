import React, { memo, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid } from '@mui/material';
import TabLayout from './TabLayout';
// import Field from '../../components/Field/Field';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Upload from '../../components/Upload/Upload';
// import DataTable from '../../components/DataTable/DataTable';
import Section from '../../components/Section/Section';
import { useMemo, useState } from 'react';
import VerticalTabLayout from './VerticalTabLayout';
import TableCoulumnHyperLink from '../../components/HyperlinkPage/TableCoulumnHyperLink';
import Chart from '../../components/Chart/Chart';
import Menu from '../../components/Menu/Menu';
import Dynamic from '../../components/dynamicpage/Dynamic';
import Http from '../../utils/http';
// import FilterDropdown from '../../components/Filter/FilterDropdown';
import Charts from '../../components/Charts/Charts';
import Tiels from '../../components/Tiels/Tiels';
import T4 from '../../components/T4/T4';
import T3 from '../../components/T3/T3';
import CustomTable from '../../components/CutomTable/CustomTable';
import BasicTable from '../../components/MuiSimpleTable/MuiSimpleTable';
import StackedBarChart from '../../components/AllTypeChart/StackedBarChart';
import MuiTextTypo from '../../components/MuiText/MuiTextTypo';
import { MiniTiels } from '../../components/Tiels/MiniTiels';
import MultiSelectCheckMarks from '../../components/MultiSelectCheckMarks/MultiSelectCheckMarks';
import SelectSmall from '../../components/MultiSelectCheckMarks/BasicSelect';
import CustomizedField from '../../components/Field/CustomizedField';
import BasicMRTTable from '../../components/CompboTable/BasicMRTTable';
import HeadersTiel from '../../components/Tiels/HeadersTiel';
import MiniSizeTable from '../../components/CompboTable/MiniSizeTable';
// import MuiDataGridTable from '../../components/CompboTable/ComboTableCellSupport';
import ComboTable from '../../components/CompboTable/ComboTable';
// import MuiDropDownGroup from '../../components/DropdownGroups/MuiDropDownGroup';
import VerticalBarcharBig from '../../components/AllTypeChart/VerticalBarcharBig';
import ThreeTielsName from '../../components/Tiels/ThreeTielsName';
// import ParentGroupDown from '../../components/DropdownGroups/GroupDropdown/ParentGroupDown';
import MuiCardTiels from '../../components/MuiCardTiels/MuiCardTiels';
import BasicTableWithColor from '../../components/CompboTable/BasicTableWithColor';
// import MultiSelectDropdown from '../../components/CustomDropDown/MultiSelect/MultiSelectDropdown';
import SingleSelectDropdown from '../../components/CustomDropDown/SingleSelectDropdown/SingleSelectDropdown';
import BasicTableDynamic from '../../components/CompboTable/BasicTableDynamic';
// import SingleParent from '../../components/DropdownGroups/SingleDropdownGroup/SingleParent';
// import PinningColumnTable from '../../components/pinningTable/PinningColumnTable';
import AggridColumnPinning from '../../components/AllTestingComponents/AgGridTable/AggridColumnPinning';
import MrtTableWithSubColumn from '../../components/AllTestingComponents/MRTTable/BasicTable/SubColumnTable';

// import TestingColumnclickAddrow from '../../components/AllTestingComponents/MRTTable/TestingColumnclickAddrow';
import LogoAndHeading from '../../components/LogoAndHeading/LogoAndHeading';
import MuiSimpleTable from '../../components/AllTestingComponents/MuiSimpleTable/MuiSimpleTable';
import TableWithPaddingColumn from '../../components/TableWithPaddingColumns/TableWithPaddingColumn';
import ShowImages from '../../components/ShowImages/ShowImage';
import ButtonAsBox from '../../components/ButtonAsBox/ButtonAsBox';
import BoxTypeLabelWithBorder from '../../components/BoxTypeLabel/BoxTypeLableWithBorder';
import TextWithNewLine from '../../components/TextWithNewLine/TextWithNewLine';
import TextWithNewLineAndLeft from '../../components/TextWithNewLine/TextWithNewLineAndLeft';
import BasicSingleminiDropDown from '../../components/BasicMiniDropdwon/BasicSingleminiDropDown';
import ShowCardTiles from '../../components/ShowCardTile/ShowCardTiles';
import SelectableMiniTabs from '../../components/SelectableMiniTabs/SelectableMiniTabs';
import TableWithMiniColumns from '../../components/MiniColumnGotTable/TableWithMiniColumns';
import CellClickableTable from '../../components/CellClickableTable/CellClickableTable';
import Multiselectcheckmarkwithoutcss from '../../components/MultiSelectCheckMarks/Multiselectcheckmarkwithoutcss';
import MonthAllCheckMark from '../../components/MultiSelectCheckMarks/MonthAllCheckMark';
// import DefaultParentDrop from '../../components/DropdownGroups/DefaultValueDropDown/DefaultParentDrop';
import MiniSizeTableParent from '../../components/MiniSizeTableVerticle/MiniSizeTableParent';
// import ParentDefaultOnchange from '../../components/DropdownGroups/DefaultDropDownWithOnchange/ParentDefaultOnchange';
// import UpdatedParentDropdwon from '../../components/DropdownGroups/UpdatedGroupDropDown/UpdatedParentDropdwon';
// import UpdatedDefaultParent from '../../components/DropdownGroups/UpdatedDefaultValueCheckDropdown/UpdatedDefaultParent';
// import SingleUpdatedParent from '../../components/DropdownGroups/SingleUpdatedDropdwon/SingleUpdatedParent';
import UpdatedGroupDropdownParentTwo from '../../components/DropdownGroups/UpdatedGroupdropdownTwo/UpdatedGroupDropdownParentTwo';
import DefaultValueCheckDropDownTwoParent from '../../components/DropdownGroups/DefaultValueCheckDropDownTwo/DefaultValueCheckDropDownTwoParent';
import SingleUpdatedDropdownParentTwo from '../../components/DropdownGroups/SingleUpdatedDropdownTwo/SingleUpdatedDropdownParentTwo';
// import DownloadPageAsPdf from '../../components/DownLoadPageAsPdf/DownloadPageAsPdf';
// import MultiSelect from '../../components/DropdownGroups/MultiSelect';
// import SingleSelect from '../../components/DropdownGroups/SingleSelect';

const LayoutItem = ({ layout, data, apidata, onClick }) => {
  const [targetVerticalTab, setTargetVerticalTab] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [present, setPresent] = useState(false);
  const [dynamic_page, setDynamic_page] = useState(null);
  const [summaryData, setSummeryData] = useState(null);

  const initialFormValues = useMemo(() => {
    let returnValue = {};
    if (data?.[0]) {
      let keys = Object.keys(data?.[0]) || [];
      if (keys?.length) {
        keys.forEach((key) => {
          returnValue[key] = '';
        });
      }
    }
    return returnValue;
  }, [data]);

  const apiCallForCreate = async (mappedObject, actionName) => {
    const mappedObjectLength = mappedObject.length;
    const checkEmpty = mappedObject.filter((i) => i?.['field_value'].length === 0);
    if (checkEmpty.length >= 1 || mappedObjectLength === 0) {
      return;
    } else {
      try {
        const response = await Http.post('/fetch-all-page-data', {
          a_id: '',
          pg_id: `${layout?.pg_id}`,
          field_values: mappedObject,
          action: {
            action_type: 'action',
            action_value: `${actionName?.[0]}`
          }
        });
        if (!response.data?.error) {
          toast.success('Record created successfully');
          let apiData = response?.data?.data;

          if (apiData?.['actionPerformed'] === 'view') {
            return;
          }
        } else {
          toast.error(response.data?.error);
        }
      } catch (e) {
        toast.error(e?.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialFormValues || {},
    onSubmit: (values, { resetForm }) => {
      let actionName = layout?.pageContent
        ?.filter((content) => content?.cntnt_ty === 'action')
        ?.map((content) => content?.actn_id);

      const mappedObject = Object.entries(values).map(([field_name, field_value]) => {
        return { field_name, field_value };
      });
      apiCallForCreate(mappedObject, actionName);
      resetForm({ values: '' });
    }
  });

  // console.log('layout@...', layout, apidata);

  return (
    <>
      {layout?.dvd_in === 'C' ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%',
            gap: 2,
            flexWrap: 'wrap'
          }}
          id="divided-in-column"
        >
          {layout?.children?.map((item) => (
            <Box
              key={item?.sctn_id}
              flex={item?.rltv_per}
              id={item?.sctn_id}
              sx={{ maxWidth: '100%' }}
            >
              <LayoutItem layout={item} dynamic_page={dynamic_page} apidata={apidata} />
            </Box>
          ))}
        </Box>
      ) : layout?.dvd_in === 'R' ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
            gap: 2
          }}
        >
          {layout?.children?.map((item) => (
            <Box key={item?.sctn_id} flex={`${item?.rltv_per || 0}%`}>
              <LayoutItem layout={item} dynamic_page={dynamic_page} apidata={apidata} />
            </Box>
          ))}
        </Box>
      ) : layout?.dvd_in === 'T' ? (
        <TabLayout
          childLayout={layout?.children}
          layout={layout}
          key={layout?.sctn_id}
          apidata={apidata}
        />
      ) : layout?.dvd_in === 'VT' ? (
        <VerticalTabLayout
          items={layout?.children}
          key={layout?.sctn_id}
          layout={layout}
          targetVerticalTab={targetVerticalTab}
          setTargetVerticalTab={setTargetVerticalTab}
          pageId={layout?.pg_id}
          dynamic_page={dynamic_page}
          initialValues_data={data}
        />
      ) : null}

      {layout?.pageContent?.length ? (
        <Grid container spacing={2} alignItems="flex-start">
          {layout?.pageContent
            ?.filter((content) => content?.cntnt_ty !== 'action')
            ?.map((content, pos) =>
              content?.cntnt_ty === 'upload' ? (
                <Grid item xs key={`${layout?.sctn_id}-upload-${pos}`}>
                  <Upload key={`${layout?.sctn_id}-upload-${pos}`} />
                </Grid>
              ) : content?.cntnt_ty === 'section' ? (
                <Grid item xs key={content.cntnt_ty + content?.sctn_id}>
                  <Section
                    key={content.cntnt_ty + content?.sctn_id}
                    layout={layout}
                    initialValues_data={data}
                    data={content}
                    pageId={layout?.pg_id}
                  />
                </Grid>
              ) : content?.pageData?.[0]?.cntnt_ty === 'chart' ? (
                <Grid item xs key={content.cntnt_ty + content?.sctn_id}>
                  <Chart key={content.cntnt_ty + content?.sctn_id} pageId={layout?.pg_id} />
                </Grid>
              ) : content?.cntnt_ty === 'menu' ? (
                <>
                  <Grid item xs={3} key={`${layout?.sctn_id}-${content?.cntnt_ty}`}>
                    <Menu
                      items={content.menuData}
                      key={content.cntnt_ty + content?.sctn_id}
                      present={present}
                      setPresent={setPresent}
                      dynamic_page={dynamic_page}
                      setDynamic_page={setDynamic_page}
                      summaryData={summaryData}
                      setSummeryData={setSummeryData}
                    />
                  </Grid>
                  <Dynamic
                    key={content.cntnt_ty + content?.sctn_id}
                    content={content}
                    present={present}
                    initialValues_data={data}
                    dynamic_page={dynamic_page}
                    summaryData={summaryData}
                    pageId={layout?.pg_id}
                  />
                </>
              ) : content?.cntnt_ty === 'dyn_pssage' ? (
                <Dynamic
                  key={content.cntnt_ty + content?.sctn_id}
                  content={content}
                  present={present}
                  dynamic_page={dynamic_page}
                  summaryData={summaryData}
                />
              ) : content?.cntnt_ty === 'field' &&
                content?.is_drpdwn === 'y' &&
                content?.drp_dn_tmplt === 'cca_drp_dn' &&
                content?.is_multi_select === null ? (
                <Grid item xs key={`${layout?.sctn_id}-field-y-${pos}`}>
                  <SingleSelectDropdown
                    fld_id={content?.fld_id}
                    layout={layout}
                    fld_l={content?.fld_l}
                    apidata={apidata}
                    content={content}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'field' &&
                content?.is_drpdwn === 'y' &&
                content?.drp_dn_tmplt === 'cca_drp_dn' &&
                content?.is_multi_select === 1 ? (
                <Grid item xs key={`${layout?.sctn_id}-field-y-${pos}`}>
                  {/* <MultiSelectDropdown
                    fld_id={content?.fld_id}
                    layout={layout}
                    fld_l={content?.fld_l}
                    apidata={apidata}
                  /> */}
                  Manager
                </Grid>
              ) : content?.cntnt_ty === 'field' &&
                content?.is_drpdwn === 'y' &&
                content?.is_multi_select === 1 &&
                content?.drp_dn_tmplt !== 'no_css' &&
                content?.fld_id !== 'f_mon_dd_all' ? (
                <Grid item xs key={`${layout?.sctn_id}-field-y-${pos}`}>
                  <MultiSelectCheckMarks
                    fld_id={content?.fld_id}
                    layout={layout}
                    fld_l={content?.fld_l}
                    apidata={apidata}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'field' &&
                content?.is_drpdwn === 'y' &&
                content?.is_multi_select === 1 &&
                content?.drp_dn_tmplt !== 'no_css' &&
                content?.fld_id === 'f_mon_dd_all' ? (
                <Grid item xs key={`${layout?.sctn_id}-field-y-${pos}`}>
                  <MonthAllCheckMark
                    fld_id={content?.fld_id}
                    layout={layout}
                    fld_l={content?.fld_l}
                    apidata={apidata}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'field' &&
                content?.is_drpdwn === 'y' &&
                content?.is_multi_select === 1 &&
                content?.drp_dn_tmplt === 'no_css' ? (
                <Grid item xs key={`${layout?.sctn_id}-field-y-${pos}`}>
                  <Multiselectcheckmarkwithoutcss
                    fld_id={content?.fld_id}
                    layout={layout}
                    fld_l={content?.fld_l}
                    apidata={apidata}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'field' &&
                content?.is_drpdwn === 'y' &&
                content?.drp_dn_tmplt === 'small_drp_dn' ? (
                <Grid item xs key={`${layout?.sctn_id}-field-y-${pos}`}>
                  <BasicSingleminiDropDown
                    fld_id={content?.fld_id}
                    layout={layout}
                    fld_l={content?.fld_l}
                    apidata={apidata}
                    DefaltValueDropdown={apidata?.fields}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'field' &&
                content?.is_drpdwn === 'y' &&
                content?.is_multi_select === null ? (
                <Grid item xs key={`${layout?.sctn_id}-field-y-${pos}`}>
                  <SelectSmall
                    fld_id={content?.fld_id}
                    layout={layout}
                    fld_l={content?.fld_l}
                    apidata={apidata}
                    DefaltValueDropdown={apidata?.fields}
                  />
                  {/* <SingleSelect
                    fld_id={content?.fld_id}
                    layout={layout}
                    fld_l={content?.fld_l}
                    apidata={apidata}
                    DefaltValueDropdown={apidata?.fields}
                  /> */}
                </Grid>
              ) : content?.cntnt_ty === 'chart' && content?.tmplt_id === 't1' ? (
                <Grid item xs key={`${layout?.sctn_id}-chart-t1-${pos}`}>
                  <Charts
                    chartParams={content?.chartParams}
                    layout={layout}
                    chrt_id={layout?.pageContent?.[0]?.chrt_id}
                    apidata={apidata}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'chart' && content?.tmplt_id === 't2' ? (
                <Grid item xs key={`${layout?.sctn_id}-chart-t2-${pos}`}>
                  <Tiels
                    chartParams={content?.chartParams}
                    layout={layout}
                    chrt_id={content?.chrt_id}
                    apidata={apidata}
                    content={content}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'chart' && content?.tmplt_id === 't4' ? (
                <Grid item xs key={`${layout?.sctn_id}-chart-t4-${pos}`}>
                  <T4
                    chartParams={content?.chartParams}
                    layout={layout}
                    chrt_id={content?.chrt_id}
                    apidata={apidata}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'chart' && content?.tmplt_id === 't3' ? (
                <Grid item xs key={`${layout?.sctn_id}-chart-t3-${pos}`}>
                  <T3
                    chartParams={content?.chartParams}
                    layout={layout}
                    chrt_id={content?.chrt_id}
                    apidata={apidata}
                    content={content}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'table' && content?.tmplt_id === 'kpid' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-chart-t3-${pos}`}>
                  <CustomTable layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'table' &&
                content?.tmplt_id === 'flm_smry' &&
                content?.is_color_tbl === 1 ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <ComboTable layout={layout} content={content} apidata={apidata} />
                  </Suspense>
                </Grid>
              ) : content?.cntnt_ty === 'table' &&
                content?.tmplt_id === 'prty' &&
                content?.is_color_tbl === null ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <BasicTable layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'table' &&
                content?.tmplt_id === 'prty' &&
                content?.is_color_tbl === 1 ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <BasicMRTTable layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'chart' && content?.chrt_ty === 'ver_bar_p2_1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <VerticalBarcharBig item={layout} chrt_id={content?.chrt_id} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'chart' && content?.chrt_ty !== 'tile' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <StackedBarChart layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'label' &&
                content?.label?.[0]?.lbl_display_type === 'box' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <ButtonAsBox layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'label' &&
                content?.label?.[0]?.lbl_display_type === 'box_with_border' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <BoxTypeLabelWithBorder layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'label' &&
                content?.label?.[0]?.lbl_display_type === 'text_on_new_line' &&
                content?.label?.[0]?.lbl_pos !== 'left' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <TextWithNewLine layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'label' &&
                content?.label?.[0]?.lbl_display_type === 'text_on_new_line' &&
                content?.label?.[0]?.lbl_pos === 'left' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <TextWithNewLineAndLeft layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'label' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <MuiTextTypo layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'chart' &&
                content?.chrt_ty === 'tile' &&
                content?.tmplt_id === 't_p2_1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <HeadersTiel layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'chart' &&
                content?.chrt_ty === 'tile' &&
                content?.tmplt_id === 't_p2_2' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <ThreeTielsName layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'chart' &&
                content?.chrt_ty === 'tile' &&
                content?.tmplt_id === 't_p2_2_tbl' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <MiniSizeTable layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'chart' &&
                content?.chrt_ty === 'tile' &&
                content?.tmplt_id === 'cca_1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <MuiCardTiels layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'chart' &&
                content?.chrt_ty === 'tile' &&
                content?.tmplt_id === 't_p3_1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <LogoAndHeading layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'chart' &&
                content?.chrt_ty === 'tile' &&
                content?.tmplt_id === 't_flm_slm_1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <SelectableMiniTabs layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'chart' &&
                content?.chrt_ty === 'tile' &&
                content?.tmplt_id !== 't_flm_slm' &&
                content?.tmplt_id !== 't8' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <ShowCardTiles layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'chart' &&
                content?.chrt_ty === 'tile' &&
                content?.tmplt_id !== 't_flm_slm' &&
                content?.tmplt_id === 't8' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <MiniTiels layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'drp_dn_grp' && content?.tbl_id === 'st1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  {/* <DefaultParentDrop layout={layout} content={content} apidata={apidata} /> */}
                  {/* <UpdatedParentDropdwon layout={layout} content={content} apidata={apidata} /> */}
                  <UpdatedGroupDropdownParentTwo
                    layout={layout}
                    content={content}
                    apidata={apidata}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'drp_dn_grp' && content?.tbl_id === 'bs1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  {/* <ParentGroupDown layout={layout} content={content} apidata={apidata} /> */}
                  {/* <UpdatedParentDropdwon layout={layout} content={content} apidata={apidata} /> */}
                  <UpdatedGroupDropdownParentTwo
                    layout={layout}
                    content={content}
                    apidata={apidata}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'drp_dn_grp' && content?.tbl_id === 'cv1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  {/* <ParentGroupDown layout={layout} content={content} apidata={apidata} /> */}
                  {/* <UpdatedParentDropdwon layout={layout} content={content} apidata={apidata} /> */}
                  <UpdatedGroupDropdownParentTwo
                    layout={layout}
                    content={content}
                    apidata={apidata}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'drp_dn_grp' && content?.tbl_id === 'ss1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  {/* <ParentDefaultOnchange layout={layout} content={content} apidata={apidata} /> */}
                  {/* <UpdatedDefaultParent layout={layout} content={content} apidata={apidata} /> */}
                  <DefaultValueCheckDropDownTwoParent
                    layout={layout}
                    content={content}
                    apidata={apidata}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'drp_dn_grp' && content?.tbl_id === 'ei1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  {/* <ParentGroupDown layout={layout} content={content} apidata={apidata} /> */}
                  {/* <UpdatedParentDropdwon layout={layout} content={content} apidata={apidata} /> */}
                  <UpdatedGroupDropdownParentTwo
                    layout={layout}
                    content={content}
                    apidata={apidata}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'drp_dn_grp' && content?.tbl_id === 'ber1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  {/* <UpdatedParentDropdwon layout={layout} content={content} apidata={apidata} /> */}
                  <UpdatedGroupDropdownParentTwo
                    layout={layout}
                    content={content}
                    apidata={apidata}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'drp_dn_grp' && content?.tbl_id === 'cca1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  {/* <SingleParent layout={layout} content={content} apidata={apidata} /> */}
                  {/* <SingleUpdatedParent layout={layout} content={content} apidata={apidata} /> */}
                  <SingleUpdatedDropdownParentTwo
                    layout={layout}
                    content={content}
                    apidata={apidata}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'drp_dn_grp' && content?.tbl_id === 'mi1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  {/* <SingleParent layout={layout} content={content} apidata={apidata} /> */}
                  {/* <SingleUpdatedParent layout={layout} content={content} apidata={apidata} /> */}
                  <SingleUpdatedDropdownParentTwo
                    layout={layout}
                    content={content}
                    apidata={apidata}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'table' && content?.tmplt_id === 'sale_t1_row_as_col' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <MiniSizeTableParent layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'table' && content?.tmplt_id === 'sale_t1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <MiniSizeTable layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'table' && content?.tmplt_id === 'cca_tbl_1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <BasicTableWithColor layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'table' && content?.tmplt_id === 'stocks_tbl_1' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <BasicTableDynamic layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'table' && content?.tmplt_id === 'ber_pinning_column' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  {/* <PinningColumnTable layout={layout} content={content} apidata={apidata} /> */}
                  <AggridColumnPinning layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'table' && content?.tmplt_id === 'ber_pinning_column' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  {/* <PinningColumnTable layout={layout} content={content} apidata={apidata} /> */}
                  <AggridColumnPinning layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'table' && content?.tmplt_id === 'cell_clickable_tbl' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <CellClickableTable layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'image' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <ShowImages layout={layout} content={content} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'table' &&
                content?.tmplt_id === 'mr_incentive_sales_col_pin' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <MrtTableWithSubColumn
                    layout={content}
                    apidata={apidata}
                    content={layout?.pageContent?.[0]}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'got' && content?.tmplt_id === 'mr_incentive_sales' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <TableWithMiniColumns item={layout} apidata={apidata} />
                </Grid>
              ) : content?.cntnt_ty === 'got' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <TableWithPaddingColumn
                    item={layout}
                    apidata={apidata}
                    // content={layout?.pageContent?.[0]}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'table' && content?.tmplt_id === 'mr_incentive_sales' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <MrtTableWithSubColumn
                    layout={content}
                    apidata={apidata}
                    content={layout?.pageContent?.[0]}
                  />
                </Grid>
              ) : content?.cntnt_ty === 'table' && content?.tmplt_id === 'mr_incentive_sales_50' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  <MuiSimpleTable />
                </Grid>
              ) : content?.cntnt_ty === 'button' ? (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  {/* <DownloadPageAsPdf 
                    layout={content}
                    apidata={apidata}
                    content={layout?.pageContent?.[0]} /> */}
                  <button onClick={onClick}>Download PDF</button>
                </Grid>
              ) : (
                <Grid item xs={12} key={`${layout?.sctn_id}-${pos}`}>
                  {content?.cntnt_ty === 'field' ? (
                    <CustomizedField
                      field={content}
                      key={`${layout?.sctn_id}-${content?.fld_id}`}
                      id={`${layout?.sctn_id}-${content?.fld_id}`}
                      // value={formik.values?.[content?.fld_id]}
                      // value={Object.keys(formik.values).length >=1 ? formik.values?.[content?.fld_id] : ""}
                      name={content?.fld_id}
                      // onChange={formik.handleChange}
                    />
                  ) : // content?.cntnt_ty
                  null}
                </Grid>
              )
            )}

          {layout?.pageContent
            ?.filter((content) => content?.cntnt_ty === 'action')
            ?.map((content) => (
              <Grid item xs key={`${layout?.sctn_id}-${content?.tbl_id}`}>
                <Button
                  variant="contained"
                  key={`${layout?.sctn_id}-${content?.actn_id}`}
                  id={`${layout?.sctn_id}-${content?.actn_id}`}
                  onClick={formik.handleSubmit}
                  sx={{ width: 'fit-content' }}
                >
                  {content?.actn_nm}
                </Button>
              </Grid>
            ))}
        </Grid>
      ) : null}
      <TableCoulumnHyperLink open={isOpen} setOpen={setIsOpen} layout={layout} />
    </>
  );
};

LayoutItem.propTypes = {
  layout: PropTypes.object,
  data: PropTypes.array,
  refetchPageData: PropTypes.func,
  apidata: PropTypes.object,
  onClick: PropTypes.any
};

export default memo(LayoutItem);
