import './App.css';
// import Dashboard from './pages/Dashboard/Dashboard';
import { RouterProvider, createBrowserRouter, defer } from 'react-router-dom';
import AppDetails from './pages/AppDetails/AppDetails';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppPage from './pages/AppPage/AppPage';
import Http from './utils/http';
import Loader from './components/Loader/Loader';
import CardMUILogoutPage from './components/Navbar/CardMuiLogoutPage';
import AuthRedirect from './components/AuthRedirect/AuthRedirect';
import LandingPage from './layouts/LandingPage/LandingPage';
import InvalidUser from './components/Navbar/InvalidUser';
import { jwtDecode } from 'jwt-decode';
const App = () => {
  const href = window.location.pathname;
  const FullLengthUrl = window.location.href;

  // const fetchPageStructure = async () => {
  //   // const accessToken = localStorage.getItem('access_token');
  //   // if an access token doesn't exist in the local storage, or if login session has expired, redirect the user to login
  //   // if (!accessToken || localStorage.getItem('expiry') < Date.now()) {
  //   //   window.location.href = '/login';
  //   // } else {
  //   //   Http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  //   // }
  //   const pageContent = Http.get('get-page-content');
  //   const pageContentData = Http.get('get-page-content-data');
  //   const menu = Http.get('get-client-app-menu');
  //   const pageSections = Http.get('get-page-section');
  //   return defer({
  //     bundle: Promise.all([pageContent, pageContentData, menu, pageSections])
  //   });
  // };

  // using this function to get emp_id which ius stored in localstorage version 2

  //**************************************************** */
  const Userlogin = async (values) => {
    const emp_id1 = localStorage.getItem('emp_id');
    if (emp_id1 === values?.email) {
      return;
    }
    try {
      const response = await Http.post('login', values);
      if (!response.data.error) {
        const userData = response?.data;
        const emp_id = userData?.data?.emp_id;
        const emp_name = userData?.data?.emp_name;
        const role_id = userData?.data?.role_id;
        localStorage.removeItem('emp_id');
        localStorage.removeItem('emp_name');
        localStorage.removeItem('SSOlogin');
        localStorage.removeItem('role_id');
        localStorage.setItem('emp_id', emp_id);
        localStorage.setItem('emp_name', emp_name);
        localStorage.setItem('role_id', role_id);
        let menuDataString = JSON.stringify(userData?.data?.menu_items);
        localStorage.setItem('MenuData', menuDataString);
      } else {
        console.log(response.data.message);
        console.log('test2...');
        try {
          await AppUrlGetApi({ params: { test: FullLengthUrl }, counter: '5' });
        } catch (apiError) {
          console.error('Error fetching additional data11:', apiError);
        }
        window.location.href = '/login';
      }
    } catch (error) {
      console.error('Invalid user', error);
      console.log('test3...');
      try {
        await AppUrlGetApi({ params: { test: FullLengthUrl }, counter: '4' });
      } catch (apiError) {
        console.error('Error fetching additional data:', apiError);
      }
      window.location.href = '/login';
    }
  };

  const AppUrlGetApi = async (Url) => {
    try {
      await Http.post('store-superman-access-token', Url);
    } catch {
      console.log('error');
    }
  };

  // const Login_To_Redirection = async () => {
  //   window.location.href = '/';
  // };

  const fetchPageStructure = async () => {
    // const location = useLocation();
    // const accessToken = localStorage.getItem('access_token');
    // if an access token doesn't exist in the local storage, or if login session has expired, redirect the user to login
    // if (!accessToken || localStorage.getItem('expiry') < Date.now()) {
    //   window.location.href = '/login';
    // } else {
    //   Http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    // }

    // const emp_id = localStorage.getItem('emp_id');
    // console.log("emp_id@#",emp_id)
    // if (!emp_id) {
    //   window.location.href = '/';
    // }

    //******************************************** */

    // if logout and tried to access this route then redirect to login page.
    // const EmployeID = localStorage.getItem('emp_id');
    // if (!EmployeID) {
    // window.location.href = '/login';
    // }

    const emp_id1 = localStorage.getItem('emp_id');
    const match = href.match(/access_token=(.*)/); // Extract the access token from the URL
    console.log('match...', href, match, FullLengthUrl);
    if (match && match[0].includes('access_token') && match[1]) {
      const accessToken = match[1];
      console.log('Access Token:', accessToken);
      // Decode the JWT
      try {
        const decoded = jwtDecode(accessToken);
        const SuperManClintId = process.env.REACT_APP_SUPERMAN_CLIENT_ID;
        const SuperManClintIdWEB = process.env.REACT_APP_SUPERMAN_WEB_CLIENT_ID;
        console.log('decoded...', decoded, SuperManClintId, SuperManClintIdWEB);
        if (decoded?.aud === SuperManClintId || decoded?.aud === SuperManClintIdWEB) {
          console.log('decoded@1...', decoded, SuperManClintId, SuperManClintIdWEB);
          localStorage.setItem('sso_login', false);
          await Userlogin({
            email: decoded?.preferred_username,
            password: '12345678'
          });
          await AppUrlGetApi({
            params: { test: FullLengthUrl },
            counter: '3'
          });
        } else {
          console.log('test4...');
          try {
            await AppUrlGetApi({
              params: { test: FullLengthUrl },
              counter: '2'
            });
          } catch {
            console.log('error 2');
          }
          window.location.href = '/login';
        }
        console.log('decoded', decoded, SuperManClintId); // prints the decoded JWT payload
      } catch (error) {
        console.error('Invalid JWT token', error);
        console.log('test5...');
        try {
          await AppUrlGetApi({
            params: { test: FullLengthUrl },
            counter: '1'
          });
        } catch {
          console.log('error 2');
        }
        window.location.href = '/login';
      }
    } else {
      console.log('No access token found in the URL.');
      const EmployeID = localStorage.getItem('emp_id');
      if (!EmployeID) {
        console.log('test6...');
        window.location.href = '/login';
      }
    }

    // const emp_id1 = localStorage.getItem('emp_id');
    const role_id1 = localStorage.getItem('role_id');
    console.log('emp_id1...', emp_id1, role_id1);

    const emp_id = localStorage.getItem('emp_id');
    const role_id = localStorage.getItem('role_id');
    const pageContent = Http.post('get-page-content', {
      emp_id: `${emp_id}`,
      role_id: `${role_id}`
    });
    const pageContentData = Http.post('get-page-content-data', {
      emp_id: `${emp_id}`,
      role_id: `${role_id}`
    });
    const pageSections = Http.post('get-page-section', {
      emp_id: `${emp_id}`,
      role_id: `${role_id}`
    });
    return defer({
      bundle: Promise.all([pageContent, pageContentData, pageSections])
    });
  };

  const router = createBrowserRouter([
    {
      path: '/auth',
      element: <AuthRedirect />
    },
    {
      path: '/logout',
      element: <CardMUILogoutPage />
    },
    {
      path: '/register',
      element: <Register />
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/',
      element: <LandingPage />
    },
    {
      path: '/invaliduser',
      element: <InvalidUser />
    },
    {
      path: '/app/:appId',
      element: <AppDetails />,
      children: [
        {
          path: ':slug/:access_tokenID?',
          element: <AppPage />,
          loader: fetchPageStructure,
          errorElement: <>Route Error</>
        }
      ]
    }
  ]);

  return (
    <ThemeProvider theme={theme}>
      {/* <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/app/:appId" element={<AppDetails />}>
            <Route path="/app/:appId/:slug" element={<AppPage />} />
          </Route>
        </Routes>
      </BrowserRouter> */}
      <RouterProvider router={router} fallbackElement={<Loader />} />
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
