import React, { useEffect } from 'react';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ToGetDropDownValueAmount } from '../../features/counter/counterSlice';
import { ToGetDropDownValuemonthSingle } from '../../features/counter/counterSlice';
import { ToGetDropDownValueTopBottomPerformers } from '../../features/counter/counterSlice';
import { ToGetval_volValue } from '../../features/counter/counterSlice';
export default function SelectSmall({ fld_id, fld_l, apidata }) {
  const dispatch = useDispatch();

  const [age, setAge] = React.useState('');
  const filteredArray = apidata?.fields?.filter((item) => item?.fld_id === `${fld_id}`);
  const DropValues = filteredArray?.[0]?.dropdown?.map((item) => item.display_name);
  // const dropDownValuesamount = useSelector((state) => state.counter.amount);
  // const dropDownValuesTopBottomPerformers = useSelector(
  //   (state) => state.counter.TopBottomPerformers
  // );

  const handleChange = (event, fld_l_Values) => {
    if (fld_l_Values === 'Month') {
      const monthMapping = {
        Jan: '01',
        Feb: '02',
        Mar: '03',
        Apr: '04',
        May: '05',
        Jun: '06',
        Jul: '07',
        Aug: '08',
        Sep: '09',
        Oct: '10',
        Nov: '11',
        Dec: '12'
      };
      const SelectedMonth = monthMapping[event.target.value];
      dispatch(ToGetDropDownValuemonthSingle(SelectedMonth));
      setAge(event.target.value);
    } else if (fld_l_Values === 'Amount Value') {
      dispatch(ToGetDropDownValueAmount(event.target.value));
      setAge(event.target.value);
    } else if (fld_l_Values === 'Top/Bottom Performers') {
      setAge(event.target.value);
      dispatch(ToGetDropDownValueTopBottomPerformers(event.target.value));
    } else if (fld_l_Values === 'Volume/Value') {
      setAge(event.target.value);
      dispatch(ToGetval_volValue(event.target.value));
    }
  };

  const BasicSelectedValues = (values) => {
    if (values === 'Amount Value') {
      return 'Lac';
    } else if (values === 'Month') {
      const monthName = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];
      const today = new Date();
      const month = today.getMonth();
      const currentMonth = monthName[month];
      return currentMonth;
    } else if (values === 'Top/Bottom Performers') {
      return 'Top';
    } else if (values === 'Volume/Value') {
      return 'Value';
    }
  };

  useEffect(() => {
    if (fld_l === 'Top/Bottom Performers') {
      dispatch(ToGetDropDownValueTopBottomPerformers('Top'));
    }
  }, []);

  return (
    <Box
      sx={{
        border: '1px dashed black',
        // width: 'fit-Content',
        backgroundColor: '#f5f5f5',
        padding: '3px',
        width: '100%'
      }}
    >
      <Stack direction="row" spacing={1}>
        <Typography fontWeight="bold">{fld_l}</Typography>
      </Stack>

      <FormControl sx={{ minWidth: '-webkit-fill-available' }} size="small">
        {/* <InputLabel id="demo-select-small-label">{fld_l}</InputLabel> */}
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={age.length ? age : BasicSelectedValues(fld_l)}
          displayEmpty
          onChange={(event) => handleChange(event, fld_l)}
        >
          {DropValues?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
          {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
      </FormControl>
    </Box>
  );
}
SelectSmall.propTypes = {
  fld_id: PropTypes.string,
  fld_l: PropTypes.string,
  layout: PropTypes.object,
  apidata: PropTypes.object,
  DefaltValueDropdown: PropTypes.array
};
