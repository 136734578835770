import React, { useEffect, useState, useCallback, useRef } from 'react';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { GroupOfDropdownNullPassing } from '../../../features/counter/counterSlice';
import { GroupOfDropdownValuePassing } from '../../../features/counter/counterSlice';
import SingleUpdatedDropdownChildTwo from './SingleUpdatedDropdownChildTwo';

const SingleUpdatedDropdownParentTwo = ({ content, apidata }) => {
  const [copyData, setCopyData] = useState([]);
  const [fieldValues, setFieldValues] = useState({});
  const [keytobefilter, setKeyToBeFilter] = useState('');
  const [valuetofilter, setValueToFilter] = useState('');
  const [ObjectValues, setObjectValues] = useState({});
  const [flag, setFlage] = useState(false);
  const dispatch = useDispatch();
  const prevFieldValuesRef = useRef();

  // get all keys and makes their value null in an object
  useEffect(() => {
    if (content?.drp_dn_grp_fld_data.length) {
      let allkeys = content?.drp_dn_grp_fld_data.map((key) => key.fld);
      const ObjectWithSelectedAndVisible = allkeys.reduce((acc, item) => {
        acc[item] = { selected_values: [], visible_values: [] };
        return acc;
      }, {});
      const MergedObjectValues = {
        ...ObjectWithSelectedAndVisible,
        changed_drp_dn: '',
        drp_dn_grp_id: content?.drp_dn_grp_id
      };
      dispatch(GroupOfDropdownNullPassing(MergedObjectValues));
    }
  }, [content?.drp_dn_grp_fld_data.length]);

  useEffect(() => {
    if (keytobefilter === '' && apidata?.drp_dn_grp_data) {
      const chart_data = apidata?.drp_dn_grp_data;
      setCopyData([chart_data]);
    } else if (keytobefilter !== '' && valuetofilter !== '' && apidata?.drp_dn_grp_data) {
      const chart_data = apidata?.drp_dn_grp_data;
      setCopyData([chart_data]);
    }
  }, [keytobefilter, apidata, valuetofilter]);

  useEffect(() => {
    if (keytobefilter !== '' && valuetofilter !== '') {
      const modifiedObjects = { ...fieldValues };
      const MergedObjectValues = { ...fieldValues, ...ObjectValues, filteredObj: ObjectValues };
      console.log(
        'modifiedObjects',
        modifiedObjects,
        ObjectValues,
        fieldValues,
        MergedObjectValues
      );
      if (JSON.stringify(MergedObjectValues) !== JSON.stringify(prevFieldValuesRef.current)) {
        dispatch(GroupOfDropdownValuePassing(MergedObjectValues));
      }
      prevFieldValuesRef.current = MergedObjectValues;
    }
  }, [keytobefilter, valuetofilter, fieldValues]);

  useEffect(() => {
    if (flag) {
      if (JSON.stringify(fieldValues) !== JSON.stringify(prevFieldValuesRef.current)) {
        const MergedObjectValues = { ...fieldValues, ...ObjectValues, filteredObj: ObjectValues };
        dispatch(GroupOfDropdownValuePassing(MergedObjectValues));
      }
    }
  }, [flag, keytobefilter, fieldValues]);

  const updateFieldValues = useCallback((fieldName, values, UpdatedValuesChartFieldOptions) => {
    setFieldValues((prev) => {
      if (JSON.stringify(prev[fieldName]) !== JSON.stringify(values)) {
        return {
          ...prev,
          changed_drp_dn: fieldName,
          drp_dn_grp_id: content?.drp_dn_grp_id,
          [fieldName]: {
            selected_values: values,
            fld: `${fieldName}`,
            visible_values: UpdatedValuesChartFieldOptions
          }
        };
      }
      return prev;
    });
  }, []);

  return (
    <div style={{ backgroundColor: content?.bg_colr, padding: '10px' }}>
      <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', justifyContent: 'center' }}>
        {apidata?.drp_dn_grp_data &&
          content?.drp_dn_grp_fld_data.map((item) => (
            <>
              {item.is_multi_sel === 0 ? (
                <SingleUpdatedDropdownChildTwo
                  key={item?.fld}
                  fld_l={item?.fld}
                  DefaultValue={item?.def_val}
                  OptionsValue={item?.fld_values}
                  apidata={[apidata?.drp_dn_grp_data]}
                  updateFieldValues={updateFieldValues}
                  fieldValues={fieldValues}
                  setKeyToBeFilter={setKeyToBeFilter}
                  keytobefilter={keytobefilter}
                  setValueToFilter={setValueToFilter}
                  setFlage={setFlage}
                  item={item}
                  content={content}
                  FieldName={item?.fld_name}
                  copyData={copyData}
                  setObjectValues={setObjectValues}
                  ID_of_Dropdown={content?.drp_dn_grp_id}
                />
              ) : (
                ''
              )}
            </>
          ))}
      </Stack>
    </div>
  );
};

export default SingleUpdatedDropdownParentTwo;
SingleUpdatedDropdownParentTwo.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object,
  apidata: PropTypes.object
};
