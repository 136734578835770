import React, { useEffect, useState, useCallback } from 'react';
import { useRef } from 'react';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { GroupOfDropdownNullPassing } from '../../../features/counter/counterSlice';
import { GroupOfDropdownValuePassing } from '../../../features/counter/counterSlice';
import UpdatedGroupDropdownMultiSelectTwo from './UpdatedGroupDropdownMultiSelectTwo';
import UpdatedGroupDropDownSingleSelectTwo from './UpdatedGroupDropDownSingleSelectTwo';

const UpdatedGroupDropdownParentTwo = ({ content, apidata }) => {
  const [copyData, setCopyData] = useState([]);
  const [filterdata, setFilterData] = useState([]);
  const [filterKey, setFilterKey] = useState([]);
  const [storeddata, setStoreddata] = useState({});
  const [applyFlag, setApplyFlage] = useState(false);
  const [fieldValues, setFieldValues] = useState({});
  const [ObjectValues, setObjectValues] = useState({});
  const prevFilterKeyLength = useRef(filterKey.length);
  const dispatch = useDispatch();
  const chart_data = apidata?.drp_dn_grp_data;

  useEffect(() => {
    if (chart_data && filterdata?.length === 0 && filterKey.length === 0) {
      const chart_data = apidata?.drp_dn_grp_data;
      setCopyData([chart_data]);
    } else if (filterdata?.length > 0 && chart_data && filterKey.length >= 1) {
      const chart_data = apidata?.drp_dn_grp_data;
      setCopyData([chart_data]);
    } else if (chart_data && filterKey.length === 0) {
      const chart_data = apidata?.drp_dn_grp_data;
      setCopyData([chart_data]);
    }
  }, [chart_data, filterdata, filterKey]);
  // this will run when filterKey is empty
  useEffect(() => {
    if (filterKey.length === 0) {
      let allkeys = content?.drp_dn_grp_fld_data.map((key) => key.fld);
      const ObjectWithSelectedAndVisible = allkeys.reduce((acc, item) => {
        acc[item] = { selected_values: [], visible_values: [] };
        return acc;
      }, {});
      const MergedObjectValues = {
        ...ObjectWithSelectedAndVisible,
        changed_drp_dn: '',
        drp_dn_grp_id: content?.drp_dn_grp_id
      };
      dispatch(GroupOfDropdownNullPassing(MergedObjectValues));
    }
  }, [filterKey]);

  useEffect(() => {
    // Set up a debounce timeout variable to avoid too many dispatches in quick succession
    const timeoutId = setTimeout(() => {
      if (filterKey.length >= 1) {
        if (applyFlag) {
          const MergedObjectValues = { ...fieldValues, ...ObjectValues, filteredObj: ObjectValues };
          dispatch(GroupOfDropdownValuePassing(MergedObjectValues));
        }
        setApplyFlage(false);
      }
      // Check if the length of filterKey has reduced
      if (filterKey.length < prevFilterKeyLength.current && prevFilterKeyLength.current !== 1) {
        const MergedObjectValues = { ...fieldValues, ...ObjectValues, filteredObj: ObjectValues };
        dispatch(GroupOfDropdownValuePassing(MergedObjectValues));
      }
      // Update the ref to the current length after processing
      prevFilterKeyLength.current = filterKey.length;
    }, 300); // Set a debounce delay of 300ms (you can adjust this value)
    // Cleanup the timeout if fieldValues, filterKey, or filterdata change quickly
    return () => clearTimeout(timeoutId);
  }, [fieldValues, filterKey, filterdata, ObjectValues]);

  const updateFieldValues = useCallback((fieldName, values, UpdatedValuesChartFieldOptions) => {
    setFieldValues((prev) => {
      console.log('debugging...', fieldName, values, UpdatedValuesChartFieldOptions);
      if (JSON.stringify(prev[fieldName]) !== JSON.stringify(values)) {
        return {
          ...prev,
          changed_drp_dn: fieldName,
          drp_dn_grp_id: content?.drp_dn_grp_id,
          [fieldName]: {
            selected_values: values,
            fld: `${fieldName}`,
            visible_values: UpdatedValuesChartFieldOptions
          }
        };
      }
      return prev;
    });
  }, []);

  console.log('chart_data...', chart_data);
  return (
    <div style={{ backgroundColor: '#f5f8f9', padding: '10px' }}>
      <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', justifyContent: 'center' }}>
        {chart_data !== undefined &&
          content?.drp_dn_grp_fld_data.map((item) => (
            <>
              {item.is_multi_sel === 1 ? (
                <UpdatedGroupDropdownMultiSelectTwo
                  key={item?.fld}
                  fld_l={item?.fld}
                  chart_data={copyData}
                  filterdata={filterdata}
                  setFilterData={setFilterData}
                  setFilterKey={setFilterKey}
                  filterKey={filterKey}
                  optionsToSelect={[chart_data]}
                  setStoreddata={setStoreddata}
                  storeddata={storeddata}
                  updateFieldValues={updateFieldValues}
                  fieldValues={fieldValues}
                  setApplyFlage={setApplyFlage}
                  DefaultValue={item?.def_val}
                  FieldName={item?.fld_name}
                  setObjectValues={setObjectValues}
                  koko={apidata?.drp_dn_grp_data}
                  ID_of_Dropdown={content?.drp_dn_grp_id}
                />
              ) : item.is_multi_sel === 0 ? (
                <UpdatedGroupDropDownSingleSelectTwo
                  chart_data={copyData}
                  fld_l={item?.fld}
                  DefaultValue={item?.def_val}
                  OptionsValue={[chart_data]}
                  setFilterKey={setFilterKey}
                  setFilterData={setFilterData}
                  updateFieldValues={updateFieldValues}
                  setApplyFlage={setApplyFlage}
                  FieldName={item?.fld_name}
                  setObjectValues={setObjectValues}
                  ID_of_Dropdown={content?.drp_dn_grp_id}
                />
              ) : (
                ''
              )}
            </>
          ))}
      </Stack>
    </div>
  );
};

export default UpdatedGroupDropdownParentTwo;
UpdatedGroupDropdownParentTwo.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object,
  apidata: PropTypes.object
};
