import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import LayoutItem from './LayoutItem';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { arr1 } from '../../features/counter/counterSlice';
import Container from '@mui/material/Container';
import { IoChevronBackCircleOutline } from 'react-icons/io5';
import { RemoveProgressBarHyperLink } from '../../features/counter/counterSlice';
import { ToGetDropDownValueYear } from '../../features/counter/counterSlice';
import { ToGetDropDownValue } from '../../features/counter/counterSlice';
import { ToGetDropDownValueMonth } from '../../features/counter/counterSlice';
import { ToGetDropDownValueRange } from '../../features/counter/counterSlice';
import { ToGetDropDownValueTopBottomPerformers } from '../../features/counter/counterSlice';

const DynamicLayout = ({
  pageContentObj,
  children,
  pageData,
  refetchPageData,
  targetPageId,
  pageSections,
  Page_a2_ApiCall
}) => {
  const [pageSectionData, setPageSectionData] = useState([]);
  const [apidata, setApiData] = useState();
  const location = useLocation();
  // redux toolkit
  const count = useSelector((state) => state.counter.arr);
  const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);
  const dropDownValues = useSelector((state) => state.counter.getDropDownValue);
  const dropDownValuesYear = useSelector((state) => state.counter.year);
  const dropDownValuesmonth = useSelector((state) => state.counter.month);
  const dropDownValuesMrName = useSelector((state) => state.counter.mrName);
  const dropDownValuesamount = useSelector((state) => state.counter.amount);
  const dropDownValuemonthSingle = useSelector((state) => state.counter.monthSingle);
  const dropDownValuesrange = useSelector((state) => state.counter.range);
  const dropDownValuesTopBottomPerformers = useSelector(
    (state) => state.counter.TopBottomPerformers
  );
  const godValue = useSelector((state) => state.counter.groupofdropDownDefaultNull);
  const godFilterValue = useSelector((state) => state.counter.groupofdropDownValuePass);
  const ccaMonthValue = useSelector((state) => state.counter.ccaMonth);
  const ccaRoleValue = useSelector((state) => state.counter.ccaRole);
  const DefaultRoleNMonth = useSelector((state) => state.counter.DefaultRoleNMonth);
  const val_volValue = useSelector((state) => state.counter.val_vol);
  const ColumnSortOfTableValue = useSelector((state) => state.counter.ColumnSortOfTable);
  const MonthAll = useSelector((state) => state.counter.monthAll);
  // toolkit redux
  const dispatch = useDispatch();
  // this code will run whenever the URL changes
  useEffect(() => {
    // This code will run whenever the URL changes
    if (hyperdata?.length) {
      RemoveProgressBarHyperlinkObject(hyperdata);
    }
    dispatch(ToGetDropDownValueMonth(''));
    dispatch(ToGetDropDownValueYear(''));
    if (dropDownValuesTopBottomPerformers) {
      dispatch(ToGetDropDownValueTopBottomPerformers(''));
    }
    if (dropDownValuesrange) {
      dispatch(ToGetDropDownValueRange(null));
    }
    if (ToGetDropDownValue) {
      dispatch(ToGetDropDownValue(''));
    }
  }, [location.pathname]);

  // this code will run whenever the targetPageId (pageId) changes
  useEffect(() => {
    dispatch(ToGetDropDownValueYear(''));
    dispatch(ToGetDropDownValueMonth(''));
    if (dropDownValuesrange) {
      dispatch(ToGetDropDownValueRange(null));
    }
    if (dropDownValuesTopBottomPerformers) {
      dispatch(ToGetDropDownValueTopBottomPerformers(''));
    }
    if (ToGetDropDownValue) {
      dispatch(ToGetDropDownValue(''));
    }
  }, [targetPageId]);

  /**
   * Parse API response to generate section content for the provided `sctn_id`
   * @param {*} sctn_id
   */

  const contentGenerator = useCallback(
    (sctn_id) => {
      let returnValue = [];
      if (pageContentObj) {
        returnValue = [
          ...pageContentObj
            .filter((contentObj) => contentObj?.pageData?.[0]?.sctn_id === sctn_id)
            .map((item) => item?.pageContent?.flat())
            .flat()
        ];
      }

      return sctn_id ? returnValue : [];
    },
    [pageContentObj]
  );

  const pageSectionStructureGenerator = useCallback(
    (pageSections) => {
      const pg_sctns = pageSections?.filter((item) => item?.pg_id === targetPageId);

      const sectionsMap = {};
      const result = [];

      // Create a map of menu items using their mnu_id
      for (const item of pg_sctns) {
        const { sctn_id } = item;
        sectionsMap[sctn_id] = { ...item, children: [], pageContent: [] };
      }
      // Iterate over the menu items again to build the hierarchy
      for (const item of pg_sctns) {
        const { prnt_sctn_id, sctn_id } = item;
        const sectionItem = sectionsMap[prnt_sctn_id];
        const pageContent = contentGenerator(sctn_id);

        sectionsMap[sctn_id].pageContent.push(...pageContent);
        if (sectionItem) {
          sectionItem.children.push(sectionsMap[sctn_id]);
        } else {
          result.push(sectionsMap[sctn_id]);
        }
      }
      return result;
    },
    [targetPageId]
  );

  useEffect(() => {
    if (targetPageId) {
      setPageSectionData(pageSectionStructureGenerator(pageSections));
    }
  }, [targetPageId, pageSections, pageSectionStructureGenerator]);

  // *redux part to store api data and use it , insted of api call for same data.
  useEffect(() => {
    if (count?.length) {
      const sorted_data = count?.filter((item) => item[targetPageId]);
      if (sorted_data.length) {
        setApiData(sorted_data[0][targetPageId]);
      } else {
        const All_Data = Page_a2_ApiCall(targetPageId);
        All_Data.then((res) => setApiData(res));
        All_Data.then((res) => dispatch(arr1({ [targetPageId]: res })));
      }
    } else {
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
      All_Data.then((res) => dispatch(arr1({ [targetPageId]: res })));
    }
  }, [targetPageId]);

  // for progress bar hyperlink only
  // useEffect(() => {
  //   if (hyperdata?.length === 3) {
  //     const All_Data = Page_a2_ApiCall(targetPageId);
  //     All_Data.then((res) => setApiData(res));
  //   }
  // }, [hyperdata]);

  // *below useEffect Will be called when ever dropdownvalues changes , year and month dropdowns .
  useEffect(() => {
    if (dropDownValues && dropDownValues[0] !== '') {
      console.log('hello1...');
      // leaderboard week select option
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
    } else if (dropDownValuesYear?.length) {
      console.log('hello2...');
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
    } else if (dropDownValuesmonth?.length) {
      console.log('hello3...');
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
    }
  }, [dropDownValues, dropDownValuesYear, dropDownValuesmonth]);

  //useEffect to run api after mr_name drop value selected
  useEffect(() => {
    if (dropDownValuesMrName?.length) {
      console.log('hello4...');
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
    }
  }, [dropDownValuesMrName?.length]);

  //single_value dropdown useEffect
  useEffect(() => {
    if (dropDownValuesamount[0]?.length || dropDownValuemonthSingle?.length) {
      console.log('hello5...');
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
    }
  }, [dropDownValuesamount, dropDownValuemonthSingle]);

  useEffect(() => {
    if (dropDownValuesrange?.[0]?.length) {
      console.log('hello6...');
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
    }
  }, [dropDownValuesrange]);

  useEffect(() => {
    if (dropDownValuesTopBottomPerformers[0]?.length) {
      console.log('hello7...');
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
    }
  }, [dropDownValuesTopBottomPerformers]);

  useEffect(() => {
    if (godValue && godValue?.[0]) {
      const allEmpty = Object.entries(godValue?.[0])
        .filter(([key]) => key !== 'changed_drp_dn' && key !== 'drp_dn_grp_id')
        .every(
          ([, item]) =>
            Array.isArray(item.selected_values) &&
            item.selected_values.length === 0 &&
            Array.isArray(item.visible_values) &&
            item.visible_values.length === 0
        );
      if (Object.keys(godValue).length !== 0 && allEmpty) {
        console.log('hello8...');
        const All_Data = Page_a2_ApiCall(targetPageId);
        All_Data.then((res) => setApiData(res));
      }
    }
  }, [godValue]);

  useEffect(() => {
    if (godFilterValue && godFilterValue[0]) {
      if (Object.entries(godFilterValue[0]).length !== 0) {
        console.log('hello9...');
        const All_Data = Page_a2_ApiCall(targetPageId);
        All_Data.then((res) => setApiData(res));
      }
    }
  }, [godFilterValue]);

  useEffect(() => {
    if (ccaRoleValue !== '') {
      console.log('hello10...');
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
    }
  }, [ccaRoleValue]);

  useEffect(() => {
    if (Array.isArray(ccaMonthValue) && ccaMonthValue.length > 0) {
      console.log('hello11...');
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
    }
  }, [ccaMonthValue]);

  useEffect(() => {
    if (Array.isArray(DefaultRoleNMonth) && DefaultRoleNMonth.length > 0) {
      // console.log('apicalling...', DefaultRoleNMonth);
      // const All_Data = Page_a2_ApiCall(targetPageId);
      // All_Data.then((res) => setApiData(res));
    }
  }, [DefaultRoleNMonth]);

  useEffect(() => {
    if (val_volValue !== '') {
      console.log('hello12...');
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
    }
  }, [val_volValue]);

  useEffect(() => {
    if (ColumnSortOfTableValue?.length !== 0) {
      console.log('hello13...');
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
    }
  }, [ColumnSortOfTableValue]);

  // Monthall dropdown useEffect
  useEffect(() => {
    if (MonthAll?.length !== 0) {
      console.log('hello14...');
      const All_Data = Page_a2_ApiCall(targetPageId);
      All_Data.then((res) => setApiData(res));
    }
  }, [MonthAll]);

  // *redux part to remove progress bar hyperlink
  const RemoveProgressBarHyperlinkObject = (hyperdata) => {
    dispatch(RemoveProgressBarHyperLink(hyperdata));
  };

  const onClick = () => {
    alert('Button clicked in Child component!');
  };
  return (
    <>
      {pageSectionData ? (
        <>
          <Box sx={{ display: 'flex' }}>
            <Container maxWidth="false" sx={{ padding: '0px' }}>
              {children}
              {/* dynamic layout */}
              {
                // parent Layout
                pageSectionData?.map((pageSection) => {
                  return (
                    <React.Fragment key={pageSection?.sctn_id}>
                      <Accordion defaultExpanded={true} disableGutters expanded={true}>
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls={pageSection.sctn_id}
                          id={pageSection.sctn_id}
                          style={{ minHeight: '10px' }}
                        >
                          {hyperdata.length && pageSection?.sctn_id === 's1' ? (
                            <span>
                              <IoChevronBackCircleOutline
                                style={{ margin: 0, fontSize: '25px' }}
                                onClick={() => {
                                  RemoveProgressBarHyperlinkObject(hyperdata);
                                }}
                              />
                            </span>
                          ) : (
                            ''
                          )}
                          <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                            {pageSection?.lbl || ''}
                          </span>
                        </AccordionSummary>
                        <AccordionDetails sx={{ backgroundColor: pageSection?.bg_colr }}>
                          <LayoutItem
                            layout={pageSection}
                            data={pageData}
                            refetchPageData={refetchPageData}
                            apidata={apidata}
                            onClick={onClick}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </React.Fragment>
                  );
                })
              }
            </Container>
          </Box>
        </>
      ) : (
        <>No data found</>
      )}
    </>
  );
};

DynamicLayout.propTypes = {
  children: PropTypes.node,
  pageContentObj: PropTypes.array,
  pageData: PropTypes.array,
  refetchPageData: PropTypes.func,
  targetPageId: PropTypes.string,
  pageSections: PropTypes.array,
  Page_a2_ApiCall: PropTypes.func,
  oneLoginme: PropTypes.func,
  fetch_user_detail: PropTypes.func
};

export default memo(DynamicLayout);
