import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

export default function DefaultValueCheckDropdownSingleTwo({
  fld_l,
  DefaultValue,
  OptionsValue,
  updateFieldValues,
  setFilterKey,
  setFilterData,
  setApplyFlage,
  FieldName,
  setObjectValues,
  ID_of_Dropdown
}) {
  // Initialize with DefaultValue as a single value
  const [age, setAge] = useState(DefaultValue);
  const chartFieldValues = [...new Set(OptionsValue?.map((object) => object?.[fld_l]))];
  const UpdatedValuesChartFieldValues = chartFieldValues?.[0]?.map((item) => item?.[fld_l]);
  const DropValues = UpdatedValuesChartFieldValues;

  // Function to handle dropdown change (only current value)
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setApplyFlage((prev) => !prev);
    setAge(selectedValue); // Set the new selected value
    updateFieldValues(fld_l, [selectedValue], [DropValues]); // Send only the current selected value to updateFieldValues
    setObjectValues((prev) => {
      return {
        ...prev,
        changed_drp_dn: fld_l,
        drp_dn_grp_id: ID_of_Dropdown,
        [fld_l]: {
          selected_values: [selectedValue],
          fld: `${fld_l}`,
          visible_values: [DropValues][0]
        }
      };
    });
    // Update filter key and filter data
    setFilterKey((prev) => [...prev, fld_l]);
    setFilterData((prev) => {
      const filteredData = prev.filter((value) => !chartFieldValues.includes(value));
      return [...filteredData, selectedValue.trim()]; // Only include the current value
    });
  };

  useEffect(() => {
    updateFieldValues(fld_l, [age], [DropValues][0]);
  }, [age, fld_l, updateFieldValues]);

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: '3px',
        width: 'fit-content'
      }}
    >
      <Stack direction="row" spacing={1}>
        <Typography>{FieldName}</Typography>
      </Stack>

      <FormControl sx={{ width: 100 }} size="small">
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          sx={{ fontSize: '12px' }}
          value={age}
          displayEmpty
          onChange={handleChange}
        >
          {DropValues?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

DefaultValueCheckDropdownSingleTwo.propTypes = {
  fld_l: PropTypes.string.isRequired,
  DefaultValue: PropTypes.string.isRequired,
  OptionsValue: PropTypes.array.isRequired,
  updateFieldValues: PropTypes.func.isRequired,
  setFilterKey: PropTypes.func.isRequired,
  setFilterData: PropTypes.func.isRequired,
  setApplyFlage: PropTypes.func,
  FieldName: PropTypes.string,
  setObjectValues: PropTypes.func,
  ID_of_Dropdown: PropTypes.string
};
