import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Checkbox,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  Select,
  Box,
  Button,
  Stack,
  Typography
} from '@mui/material';
import { LuFilterX } from 'react-icons/lu';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { GroupOfDropdownValuePassing } from '../../../features/counter/counterSlice';
import BasicMenu from '../../MuiMenu/Menu';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 'auto'
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  }
};

const UpdatedGroupDropdownMultiSelectTwo = ({
  fld_l,
  chart_data,
  setFilterData,
  setFilterKey,
  optionsToSelect,
  filterKey,
  updateFieldValues,
  fieldValues,
  setApplyFlage,
  FieldName,
  // filterdata,
  setObjectValues,
  ID_of_Dropdown
}) => {
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const PreviousValuesRef = useRef();

  const chartFieldValues = useMemo(
    () => [...new Set(chart_data?.map((object) => object?.[fld_l]))],
    [chart_data, fld_l]
  );
  const UpdatedValuesChartFieldValues = useMemo(
    () => chartFieldValues?.[0]?.map((item) => item?.[fld_l]),
    [chartFieldValues]
  );

  const dropdownOptions = useMemo(
    () => [...new Set(optionsToSelect?.map((object) => object?.[fld_l]))],
    [optionsToSelect, fld_l]
  );
  const UpdatedValuesChartFieldOptions = useMemo(
    () => dropdownOptions?.[0]?.map((item) => item?.[fld_l]),
    [dropdownOptions]
  );

  const multiDropShowOptions = useMemo(
    () =>
      filterKey.includes(fld_l) ? UpdatedValuesChartFieldOptions : UpdatedValuesChartFieldValues,
    [filterKey, fld_l, UpdatedValuesChartFieldOptions, UpdatedValuesChartFieldValues]
  );

  const isAllSelected =
    multiDropShowOptions?.length > 0 && selected.length === multiDropShowOptions?.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelected(isAllSelected ? [] : multiDropShowOptions);
      return;
    }
    setSelected(value);
  };

  const handleChangeRedux = (selected, fieldName, options) => {
    if (selected.length === options.length) {
      setFilterKey([]);
      setFilterData([...selected]);
      setObjectValues((prev) => {
        return {
          ...prev,
          changed_drp_dn: fieldName,
          drp_dn_grp_id: ID_of_Dropdown,
          [fieldName]: {
            selected_values: [selected][0],
            fld: `${fieldName}`,
            visible_values: [options][0]
          }
        };
      });
    } else {
      setFilterKey((prev) => [...prev, fieldName]);
      setFilterData((prev) => [...prev, ...selected]);
      setApplyFlage((prev) => !prev);
      setObjectValues((prev) => {
        return {
          ...prev,
          changed_drp_dn: fieldName,
          drp_dn_grp_id: ID_of_Dropdown,
          [fieldName]: {
            selected_values: [selected][0],
            fld: `${fieldName}`,
            visible_values: [options][0]
          }
        };
      });
    }
  };

  // useEffect(() => {
  //   if (chart_data?.length) {
  //     if (
  //       JSON.stringify(UpdatedValuesChartFieldValues) !== JSON.stringify(PreviousValuesRef.current)
  //     ) {
  //       setSelected([...UpdatedValuesChartFieldValues]);
  //       PreviousValuesRef.current = UpdatedValuesChartFieldValues;
  //     }
  //   }
  // }, [chart_data]);

  useEffect(() => {
    if (chart_data?.length && selected.length === 0) {
      const initialValues =
        JSON.stringify(UpdatedValuesChartFieldValues) !== JSON.stringify(PreviousValuesRef.current)
          ? UpdatedValuesChartFieldValues
          : selected;
      setSelected([...initialValues]);
      PreviousValuesRef.current = UpdatedValuesChartFieldValues;
    } else if (chart_data?.length && selected.length > 0) {
      if (!filterKey.includes(fld_l)) {
        setSelected([...UpdatedValuesChartFieldValues]);
      }
    }
  }, [chart_data]);

  const cancelfilterIcon = (fieldName, selected1) => {
    if (filterKey.length === 1) {
      // setFilterData([]);
      dispatch(GroupOfDropdownValuePassing({}));
    }
    setFilterKey((prev) => prev.filter((item) => item !== fieldName));
    setFilterData((prev) => prev.filter((item) => !selected1.includes(item)));
    // setSelected([]); // Clear selections when canceling
    setObjectValues((prev) => {
      // eslint-disable-next-line no-unused-vars
      const { [fieldName]: _, ...rest } = prev;
      return rest;
    });
  };

  // //keep updated the field values
  useEffect(() => {
    updateFieldValues(fld_l, UpdatedValuesChartFieldOptions, UpdatedValuesChartFieldOptions);
  }, [selected]);

  return (
    <Box sx={{ backgroundColor: '#f5f5f5', padding: '3px', width: 'fit-content' }}>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <Typography marginLeft="3px">{FieldName}</Typography>
        <Stack direction="row" spacing={1} sx={{ marginTop: '10px' }}>
          <Box>
            {filterKey.includes(fld_l) ? (
              <LuFilterX
                cursor="pointer"
                color="red"
                onClick={() => cancelfilterIcon(fld_l, selected)}
              />
            ) : (
              <LuFilterX />
            )}
          </Box>
          <BasicMenu />
        </Stack>
      </Stack>
      <FormControl sx={{ width: 100 }} size="small">
        <InputLabel id="demo-multiple-checkbox-label">{}</InputLabel>
        <Select
          labelId="mutiple-select-label"
          multiple
          sx={{ fontSize: '12px' }}
          value={selected}
          onChange={handleChange}
          renderValue={(selected) =>
            UpdatedValuesChartFieldValues?.length === 1
              ? isAllSelected
                ? UpdatedValuesChartFieldValues?.[0]
                : selected.join(', ')
              : isAllSelected
              ? 'All'
              : selected.join(', ')
          }
          MenuProps={MenuProps}
        >
          <MenuItem value="all" sx={{ padding: '0' }}>
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  selected?.length > 0 && selected?.length < multiDropShowOptions?.length
                }
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>

          {multiDropShowOptions?.map((option, index) => (
            <MenuItem key={index} value={option} sx={{ padding: '0' }}>
              <ListItemIcon>
                <Checkbox checked={selected.includes(option)} />
              </ListItemIcon>
              <ListItemText primary={option} sx={{ padding: '5px' }} />
            </MenuItem>
          ))}
          <Stack direction="row" spacing={2} justifyContent="center" marginTop="5px">
            <Button variant="outlined" sx={{ color: '#000000' }} onClick={() => setSelected([])}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{ color: '#000000' }}
              onClick={() => handleChangeRedux(selected, fld_l, multiDropShowOptions, fieldValues)}
            >
              Apply
            </Button>
          </Stack>
        </Select>
      </FormControl>
    </Box>
  );
};

export default React.memo(UpdatedGroupDropdownMultiSelectTwo);

UpdatedGroupDropdownMultiSelectTwo.propTypes = {
  fld_l: PropTypes.string.isRequired,
  chart_data: PropTypes.array.isRequired,
  setFilterData: PropTypes.func.isRequired,
  setFilterKey: PropTypes.func.isRequired,
  optionsToSelect: PropTypes.array.isRequired,
  filterKey: PropTypes.array.isRequired,
  storeddata: PropTypes.object,
  myObject: PropTypes.object,
  updateFieldValues: PropTypes.func,
  fieldValues: PropTypes.object,
  setApplyFlage: PropTypes.func,
  DefaultValue: PropTypes.string,
  FieldName: PropTypes.string,
  filterdata: PropTypes.array,
  setObjectValues: PropTypes.func,
  ID_of_Dropdown: PropTypes.string
};
