import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NestedTabs from '../NestedTabs/NestedTabs';
import ComposedCharts from '../ComposedChart/ComposedChart';
import Card from '@mui/material/Card';
// import Grid from '@mui/material/Unstable_Grid2';
import HorizontalBar from '../AllTypeChart/HorizontalBar';
import BasicTable from '../MuiSimpleTable/MuiSimpleTable';
// import { useDispatch, useSelector } from 'react-redux';
// import { ProgressBarHyperLink } from '../../features/counter/counterSlice';
// import { RemoveProgressBarHyperLink } from '../../features/counter/counterSlice';
// import Chrt from '../TestingComponents/Charts';
import VerticalBarChart from '../AllTypeChart/VerticalBarChart';
import BasicMRTTable from '../CompboTable/BasicMRTTable';
import LineChartComponent from '../AllTypeChart/LineChart';
import SecondeLayout from '../SecondLayout/SecondeLayout';
import { fetchContentData, fetchSectionData } from '../../utils/SecondLayoutApiCall';
import SubColumnTable from '../AllTestingComponents/MRTTable/BasicTable/SubColumnTable';
import ParentTable from '../NestedTables/ParentTable';
// import NestedTable from '../NestedTables/NestedTable';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

// actual function ********
export default function MuiTabs({ childLayout, layout, apidata }) {
  const [value, setValue] = React.useState(0);
  const [miniTile, setMiniTile] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [hyprerlinkPageID, setHyprerlinkPageID] = React.useState({});

  // const dispatch = useDispatch();
  // const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function containsInteger(text) {
    // Regular expression to match integers
    var integerPattern = /\d+/;

    // Test if the text contains an integer
    return integerPattern.test(text);
  }

  const handleClickOpen = async (item) => {
    // let DynamicObjectForBar = {
    //   main_tab: layout?.lbl,
    //   sub_tab: item?.lbl,
    //   parameter_key_name: chart_data?.chartDetail?.[1]?.fld_id,
    //   parameter_key_value: data?.[chart_data?.chartDetail?.[1]?.fld_id]
    // };

    //************redux hyperpage link call here */
    // if (item?.hpr_pg_id) {
    //   // below condition is for empty redux state
    //   if (hyperdata.length) {
    //     RemoveProgressBarHyperlinkObject(hyperdata);
    //   }
    //   dispatch(ProgressBarHyperLink({ ['hyperPage_id']: item?.hpr_pg_id }));
    //   dispatch(ProgressBarHyperLink({ ['DefaultBrowserPageId']: layout?.pg_id }));
    //   // dispatch(ProgressBarHyperLink({ ['BarInfo']: DynamicObjectForBar }));
    // }

    // secondlayout code props here
    if (item?.hpr_pg_id) {
      const contentdata = await fetchContentData(item?.hpr_pg_id);
      const sectiondata = await fetchSectionData(item?.hpr_pg_id);
      setHyprerlinkPageID({
        HyperLinkPageId: item?.hpr_pg_id,
        DynamicObjectForBar: {},
        fetch_Content_Data: contentdata,
        fetch_Section_Data: sectiondata
      });
      setOpen(true);
    }
  };

  // const RemoveProgressBarHyperlinkObject = (hyperdata) => {
  //   dispatch(RemoveProgressBarHyperLink(hyperdata));
  //   dispatch(RemoveProgressBarHyperLink());
  // };

  return (
    <>
      {/*
    
    
    <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{ alignItems: 'baseline'}} >
          {miniTile?.chartParams?.map((items, index) => (
            <>
              {containsInteger(items?.['chartParamsValue']?.[0]?.['prm_val']) ? (
                <Grid item key={index}>
                  <Card
                    variant="outlined"
                    sx={{
                      borderRadius: '18px',
                      height: '53px',
                      width: '6rem',
                      cursor: 'pointer',
                      backgroundColor: items?.hpr_pg_id ? '#f9e9e0' : '#E5F3FF',
                      color: items?.hpr_pg_id ? '#FF0000' : '#00008B'
                    }}
                    onClick={() => handleClickOpen(items)}
                  >
                    <Box sx={{ textAlign: 'center', marginTop: '0.8em' }}>
                      <Typography gutterBottom fontWeight="700">
                        {items?.['chartParamsValue']?.[0]?.['prm_val']}
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              ) : items?.['chartParamsValue']?.[0]?.['prm_val'] ? (
                <Grid item md={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    sx={{ marginTop: '1rem', fontWeight: '700', color: 'black' }}
                  >
                    {items?.['chartParamsValue']?.[0]?.['prm_val']}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
            </>
          ))}
          
       
       <Grid item  md={4}>
            {layout?.tab_position === 'left' ? (
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  '& .Mui-selected': {
                    color: 'black !important',
                    backgroundColor: '#BBE4F2'
                  },
                  '& .MuiTabs-indicator': {
                    height: '6px',
                    backgroundColor: '#00008b',
                    borderRadius: '5px'
                  }
                }}
              >
                {childLayout?.map((item, index) => (
                  <Tab
                    key={item?.sctn_id}
                    label={item?.['lbl'] || 'No name'}
                    {...a11yProps(index)}
                    sx={{ fontWeight: '800', fontSize: '15px', color: '#333333' }}
                  />
                ))}
              </Tabs>
            ) : layout?.tab_position === 'center' ? (
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  '& .Mui-selected': {
                    color: 'black !important',
                    backgroundColor: '#BBE4F2'
                  },
                  '& .MuiTabs-indicator': {
                    display: 'none'
                  }
                }}
              >
                {childLayout?.map((item, index) => (
                  <Tab
                    key={item?.sctn_id}
                    label={item?.['lbl'] || 'No name'}
                    {...a11yProps(index)}
                    sx={{
                      fontWeight: '800',
                      color: 'black',
                      width: '100%',
                      maxWidth: '50%',
                      border: '4px groove'
                    }}
                  />
                ))}
              </Tabs>
            ) : (
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  '& .Mui-selected': {
                    color: 'white !important',
                    backgroundColor: '#1976d2'
                  },
                  '& .MuiTabs-indicator': {
                    display: 'none'
                  },
                  '& .MuiTabs-flexContainer': {
                    justifyContent: 'flex-end'
                  }
                }}
              >
                {childLayout?.map((item, index) => (
                  <Tab
                    key={item?.sctn_id}
                    label={item?.['lbl'] || 'No name'}
                    {...a11yProps(index)}
                    sx={{
                      borderRadius: 20, // Adjust the value to change the curvature of the tab capsule
                      backgroundColor: '#cccccc', // Background color of the tab
                      // minWidth: 'auto', // Adjust the width of the tab to fit the content
                      padding: '6px 8px',
                      color: 'white',
                      marginRight: '0.2em',
                      fontSize: 'smaller',
                      minWidth: '5rem',
                      minHeight: '2rem',
                      '&.Mui-selected': {
                        backgroundColor: '#00008B' // Change background color for selected tab
                      }
                    }}
                  />
                ))}
              </Tabs>
            )}
          </Grid>
        </Grid>
      </Box>
    */}

      <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: '6px' }} gap={2}>
        {miniTile?.chartParams?.map((items, index) => (
          <>
            {containsInteger(items?.['chartParamsValue']?.[0]?.['prm_val']) ? (
              <Card
                key={index}
                variant="outlined"
                sx={{
                  borderRadius: '18px',
                  height: '53px',
                  width: '6rem',
                  cursor: 'pointer',
                  backgroundColor: items?.hpr_pg_id ? '#f9e9e0' : '#E5F3FF',
                  color: items?.hpr_pg_id ? '#FF0000' : '#00008B'
                }}
                onClick={() => handleClickOpen(items)}
              >
                <Box sx={{ textAlign: 'center', marginTop: '0.8em' }}>
                  <Typography gutterBottom fontWeight="700">
                    {items?.['chartParamsValue']?.[0]?.['prm_val']}
                  </Typography>
                </Box>
              </Card>
            ) : items?.['chartParamsValue']?.[0]?.['prm_val'] ? (
              <Typography
                variant="subtitle2"
                gutterBottom
                sx={{ marginTop: '1rem', fontWeight: '700', color: 'black' }}
              >
                {items?.['chartParamsValue']?.[0]?.['prm_val']}
              </Typography>
            ) : (
              <></>
            )}
          </>
        ))}
        {/* tab code below */}

        <Box sx={{ flexGrow: 1 }}>
          {layout?.tab_position === 'left' ? (
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                '& .Mui-selected': {
                  color: 'black !important',
                  backgroundColor: '#BBE4F2'
                },
                '& .MuiTabs-indicator': {
                  height: '6px',
                  backgroundColor: '#00008b',
                  borderRadius: '5px'
                }
              }}
            >
              {childLayout?.map((item, index) => (
                <Tab
                  key={item?.sctn_id}
                  label={item?.['lbl'] || 'No name'}
                  {...a11yProps(index)}
                  sx={{ fontWeight: '800', fontSize: '15px', color: '#333333' }}
                />
              ))}
            </Tabs>
          ) : layout?.tab_position === 'center' ? (
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                '& .Mui-selected': {
                  color: 'black !important',
                  backgroundColor: '#BBE4F2'
                },
                '& .MuiTabs-indicator': {
                  display: 'none'
                }
              }}
            >
              {childLayout?.map((item, index) => (
                <Tab
                  key={item?.sctn_id}
                  label={item?.['lbl'] || 'No name'}
                  {...a11yProps(index)}
                  sx={{
                    fontWeight: '800',
                    color: 'black',
                    width: '100%',
                    maxWidth: '50%',
                    border: '4px groove'
                  }}
                />
              ))}
            </Tabs>
          ) : (
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                '& .Mui-selected': {
                  color: 'white !important',
                  backgroundColor: '#1976d2'
                },
                '& .MuiTabs-indicator': {
                  display: 'none'
                },
                '& .MuiTabs-flexContainer': {
                  justifyContent: 'flex-end'
                }
              }}
            >
              {childLayout?.map((item, index) => (
                <Tab
                  key={item?.sctn_id}
                  label={item?.['lbl'] || 'No name'}
                  {...a11yProps(index)}
                  sx={{
                    borderRadius: 20, // Adjust the value to change the curvature of the tab capsule
                    backgroundColor: '#cccccc', // Background color of the tab
                    // minWidth: 'auto', // Adjust the width of the tab to fit the content
                    padding: '6px 8px',
                    color: 'white',
                    marginRight: '0.2em',
                    fontSize: '0.7rem',
                    minWidth: 'fit-content',
                    minHeight: 'fit-content',
                    '&.Mui-selected': {
                      backgroundColor: '#00008B' // Change background color for selected tab
                    }
                  }}
                />
              ))}
            </Tabs>
          )}
        </Box>
      </Box>

      {childLayout?.map((item, index) => (
        <CustomTabPanel value={value} index={index} key={item?.sctn_id}>
          {item?.pageContent?.[0]?.chrt_ty === 'hor_bar_oo100p' ? (
            <NestedTabs
              childLayout={childLayout}
              item={item}
              layout={layout}
              chrt_id={item?.pageContent?.[0]?.['chrt_id']}
              apidata={apidata}
              setMiniTile={setMiniTile}
            />
          ) : item?.pageContent?.[0]?.chrt_ty === 'bar_line_dot' ? (
            <ComposedCharts
              childLayout={childLayout}
              layout={layout}
              item={item}
              chrt_id={item?.pageContent?.[0]?.['chrt_id']}
              apidata={apidata}
            />
          ) : item?.pageContent?.[0]?.chrt_ty === 'line' ? (
            <LineChartComponent
              childLayout={childLayout}
              layout={layout}
              item={item}
              chrt_id={item?.pageContent?.[0]?.['chrt_id']}
              apidata={apidata}
            />
          ) : item?.dvd_in === 'T' && item?.children.length ? (
            <HorizontalBar
              childLayout={childLayout}
              layout={layout}
              chrt_id={item?.children?.[0]?.pageContent?.[0]?.['chrt_id']}
              apidata={apidata}
              item={item}
            />
          ) : item?.pageContent?.[0]?.cntnt_ty === 'table' &&
            item?.pageContent?.[0]?.tmplt_id === 'sales' ? (
            <SubColumnTable
              childLayout={childLayout}
              layout={item}
              chrt_id={item?.children?.[0]?.pageContent?.[0]?.['chrt_id']}
              apidata={apidata}
              content={item?.pageContent?.[0]}
            />
          ) : item?.pageContent?.[0]?.cntnt_ty === 'table' &&
            item?.pageContent?.[0]?.is_color_tbl === null ? (
            <BasicTable
              childLayout={childLayout}
              layout={item}
              chrt_id={item?.children?.[0]?.pageContent?.[0]?.['chrt_id']}
              apidata={apidata}
              content={item?.pageContent?.[0]}
            />
          ) : item?.pageContent?.[0]?.cntnt_ty === 'table' &&
            item?.pageContent?.[0]?.tmplt_id === 'kpid' &&
            item?.pageContent?.[0]?.is_color_tbl === 1 ? (
            <BasicMRTTable
              childLayout={childLayout}
              layout={item}
              chrt_id={item?.children?.[0]?.pageContent?.[0]?.['chrt_id']}
              apidata={apidata}
              content={item?.pageContent?.[0]}
            />
          ) : item?.pageContent?.[0]?.chrt_ty === 'ver_bar' ? (
            <VerticalBarChart
              childLayout={childLayout}
              layout={layout}
              item={item}
              chrt_id={item?.pageContent?.[0]?.['chrt_id']}
              apidata={apidata}
            />
          ) : item?.pageContent?.[0]?.cntnt_ty === 'got' ? (
            <ParentTable item={item} apidata={apidata} />
          ) : (
            <Typography variant="h5" gutterBottom>
              No content for this tab
            </Typography>
          )}
        </CustomTabPanel>
      ))}

      {open && <SecondeLayout open={open} setOpen={setOpen} Page_id_input={hyprerlinkPageID} />}
    </>
  );
}

MuiTabs.propTypes = {
  childLayout: PropTypes.array,
  layout: PropTypes.object,
  apidata: PropTypes.object
};
